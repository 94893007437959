import { Brand, BrandName } from '@/models/stores/brands';
import { Region } from '@/models/stores/regions';
import { LocalStorageKeys } from '@/constants';

// keep brand, region  before clear to ease next login
export default class PrevLoginSelections {
  constructor() {
    if (this instanceof PrevLoginSelections) {
      throw Error('A static class cannot be instantiated.');
    }
  }

  static prevBrand: string | null = null;
  static prevRegion: Region | null = null;
  static prevBrandName: BrandName | null = null;

  static storeSelections() {
    this.prevBrand = localStorage.getItem(LocalStorageKeys.CurrentBrand);
    this.prevRegion = localStorage.getItem(
      LocalStorageKeys.CurrentRegion
    ) as Region | null;
  }

  static setSelections() {
    if (this.prevBrand) {
      this.prevBrandName = (JSON.parse(this.prevBrand) as Brand).name;
      localStorage.setItem(
        LocalStorageKeys.PrevBrandNameForLogin,
        this.prevBrandName
      );
    }
    if (this.prevRegion) {
      localStorage.setItem(
        LocalStorageKeys.PrevRegionForLogin,
        this.prevRegion
      );
    }
  }

  static getSelections() {
    this.prevBrandName = localStorage.getItem(
      LocalStorageKeys.PrevBrandNameForLogin
    ) as BrandName;

    this.prevRegion = localStorage.getItem(
      LocalStorageKeys.PrevRegionForLogin
    ) as Region;

    return {
      prevRegion: this.prevRegion,
      prevBrandName: this.prevBrandName
    };
  }
}
